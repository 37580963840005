import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import InfoCard from '../elements/InfoCard';

function Information({ showImages }) {
  const header1 = 'post.sozial';
  const text1 =
    'Wir kümmern uns um die betrieblichen Sozialleistungen für die Mitarbeiter*innen der Österreichischen Post AG und deren Tochterunternehmen. Dies gilt auch für ehemalige Beschäftigte im Ruhestand sowie Angehörige und Hinterbliebene von Mitarbeiter*innen.';
  const imgage1 = {
    src: 'https://www.datocms-assets.com/16261/1652564817-logo.png',
    alt: 'Logo Post.Sozial',
  };
  const header2 = 'Reisen';
  const text2 =
    'Wir bieten kostengünstige Reiseangebote. Vom City-Trip bis zum Wellness- oder Familienurlaub. Hier ist für jeden etwas dabei.';
  const imgage2 = {
    src: 'https://www.datocms-assets.com/16261/1652564786-logo-reisen.png',
    alt: 'Logo Fair.Reisen',
  };
  const header3 = 'Veranstaltungen';
  const text3 =
    'Unsere Kulturangebote sind vielfältig. Sie reichen von Theater, Musical, Konzerten, Opernaufführungen bis zur Sportveranstaltung.';
  const imgage3 = {
    src: 'https://www.datocms-assets.com/16261/1652564789-logo-sehen.png',
    alt: 'Logo Sehens.Wert',
  };
  const header4 = 'Feriencamps';
  const text4 =
    'Ihre Kinder liegen uns am Herzen. Unser Angebot reicht von Sprachferien, Abenteuer-, Activity-, Fußball- und Tenniscamps bis hin zum American Musical Camp.';
  const imgage4 = {
    src: 'https://www.datocms-assets.com/16261/1652564785-logo-kids.png',
    alt: 'Logo Postler.Kids',
  };
  const header5 = 'Einkaufsvorteile';
  const text5 =
    'Unser Einkaufsportal bietet vergünstigte Einkaufsmöglichkeiten aus verschiedensten Produktgruppen.';
  const imgage5 = {
    src: 'https://www.datocms-assets.com/16261/1653399173-logo-shoppen.png',
    alt: 'Logo Besser.Shoppen',
  };
  const header6 = 'Unterstützungen';
  const text6 =
    'Wir unterstützen unsere Mitarbeiter*innen  - je nach persönlichem Jahresbruttoeinkommen - mit finanziellen Unterstützungen bei außergewöhnlichen Belastungen wie Krankheit oder Naturkatastrophen.';
  const imgage6 = {
    src: 'https://www.datocms-assets.com/16261/1652564782-logo-helfen.png',
    alt: 'Logo Helfens.Wert',
  };
  return (
    <StyledBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: 'auto' }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            {showImages && (
              <Stack spacing={{ xs: 4, md: 8 }}>
                <InfoCard image={imgage1} title={header1} description={text1} />
                <InfoCard image={imgage2} title={header2} description={text2} />
                <InfoCard image={imgage3} title={header3} description={text3} />
              </Stack>
            )}
            {!showImages && (
              <Stack spacing={{ xs: 4, md: 8 }}>
                <InfoCard title={header1} description={text1} />
                <InfoCard title={header2} description={text2} />
                <InfoCard title={header3} description={text3} />
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: 'auto' }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            {showImages && (
              <Stack spacing={{ xs: 4, md: 8 }}>
                <InfoCard image={imgage4} title={header4} description={text4} />
                <InfoCard image={imgage5} title={header5} description={text5} />
                <InfoCard image={imgage6} title={header6} description={text6} />
              </Stack>
            )}
            {!showImages && (
              <Stack spacing={{ xs: 4, md: 8 }}>
                <InfoCard title={header4} description={text4} />
                <InfoCard title={header5} description={text5} />
                <InfoCard title={header6} description={text6} />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
}

Information.defaultProps = {
  showImages: false,
};

Information.propTypes = {
  showImages: PropTypes.bool,
};

export default Information;
