import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getSrc } from 'gatsby-plugin-image';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledButton from '../../components/controls/StyledButton';

// custom components
import PopupCard from '../../components/shapes/Cards/BackgroundCards/ActionBackgroundCard';
import ContentElement from '../elements/ContentElement';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PromotionPopup({ promoSettings }) {
  // state
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // event handler
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // parameters
  const promoActive = promoSettings && promoSettings.active ? promoSettings.active : false;
  const promoTitle = promoSettings ? promoSettings.title : '';
  const promoTeaser = promoSettings ? promoSettings.teaser : '';
  const promoContent = promoSettings ? promoSettings.content : '';
  const promoFooter = promoSettings ? promoSettings.footer : '';
  const promoButton = promoSettings ? promoSettings.button : 'Open';
  const bnPrimary = promoSettings && promoSettings.buttonMain ? promoSettings.buttonMain : {};
  const bnSecondary = promoSettings && promoSettings.buttonSub ? promoSettings.buttonSub : {};

  // button image
  const baseImage = promoSettings && promoSettings.imageBase ? promoSettings.imageBase : {};
  const hasBaseImage = !_.isEmpty(baseImage);
  const baseImageSrc = hasBaseImage ? getSrc(baseImage.gatsbyImageData) : '';

  // cover image
  const coverImage = promoSettings && promoSettings.imageCover ? promoSettings.imageCover : {};
  const hasCoverImage = !_.isEmpty(coverImage);
  const coverImageSrc = hasCoverImage ? getSrc(coverImage.gatsbyImageData) : '';

  // action button
  const actionProps = { type: 'event', label: promoButton };

  return (
    <>
      {promoActive && (
        <StyledBox mx={2} my={2}>
          <StyledBox px={1} py={1} borderRadius="xl" shadow="md" bgColor="white">
            <PopupCard
              image={baseImageSrc}
              title={promoTitle}
              action={actionProps}
              onActionClick={handleOpen}
            />
          </StyledBox>
          <BootstrapDialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <BootstrapDialogTitle id="scroll-dialog-title" onClose={handleClose}>
              {promoTitle}
            </BootstrapDialogTitle>
            <DialogContent dividers={true}>
              {coverImageSrc && (
                <StyledBox
                  height="30vh"
                  width="100%"
                  mb={3}
                  sx={{
                    backgroundImage: ({
                      functions: { linearGradient, rgba },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.2),
                        rgba(gradients.dark.state, 0.2)
                      )}, url(${coverImageSrc})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></StyledBox>
              )}
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <ContentElement teaser={promoTeaser} body={promoContent} footer={promoFooter} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <StyledBox pr={1.5}>
                <StyledButton variant="text" color="info" onClick={handleClose}>
                  Schließen
                </StyledButton>
                {bnSecondary.active && (
                  <StyledButton
                    variant="text"
                    color="info"
                    to={bnSecondary.link}
                    href={bnSecondary.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {bnSecondary.label}
                  </StyledButton>
                )}
                {bnPrimary.active && (
                  <StyledButton
                    autoFocus
                    variant="gradient"
                    color="info"
                    to={bnPrimary.link}
                    href={bnPrimary.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {bnPrimary.label}
                  </StyledButton>
                )}
              </StyledBox>
            </DialogActions>
          </BootstrapDialog>
        </StyledBox>
      )}
    </>
  );
}

PromotionPopup.propTypes = {
  promoSettings: PropTypes.instanceOf(Object),
};

export default PromotionPopup;
