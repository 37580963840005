import React from 'react';
import PropTypes from 'prop-types';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';

function InfoCard({ image, title, description, direction, small }) {
  const hasImage = !!image;

  return (
    <StyledBox lineHeight={1} p={direction === 'center' ? 2 : 0} textAlign={direction}>
      {hasImage && (
        <StyledBox mb={1}>
          <img src={image.src} alt={image.alt} />
        </StyledBox>
      )}
      <StyledTypography
        display="block"
        variant="5"
        fontWeight="bold"
        mt={direction === 'center' ? 1 : 2}
        mb={1.5}
      >
        {title}
      </StyledTypography>
      <StyledTypography
        display="block"
        variant={small ? 'button' : 'body2'}
        color="text"
        pr={direction === 'left' ? 6 : 0}
        pl={direction === 'right' ? 6 : 0}
      >
        {description}
      </StyledTypography>
    </StyledBox>
  );
}

InfoCard.defaultProps = {
  direction: 'left',
  small: false,
};

InfoCard.propTypes = {
  image: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  small: PropTypes.bool,
};

export default InfoCard;
