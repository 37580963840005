//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

import React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';

// Otis Kit PRO components
import StyledBox from '../../../../controls/StyledBox';
import StyledButton from '../../../../controls/StyledButton';
import StyledTypography from '../../../../controls/StyledTypography';

function ActionBackgroundCard({ image, label, title, description, action, onActionClick }) {
  return (
    <Card
      sx={({
        functions: { rgba, linearGradient },
        palette: { black },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(black.main, 0.2),
          rgba(black.main, 0.2)
        )}, url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: borderRadius.xl,
      })}
    >
      <StyledBox textAlign="center" pt={12} pb={12} px={3}>
        {label && (
          <StyledTypography variant="body2" color="white" textTransform="uppercase" mb={2}>
            {label}
          </StyledTypography>
        )}
        {title && (
          <StyledTypography variant="h3" color="white">
            {title}
          </StyledTypography>
        )}
        {description && (
          <StyledTypography variant="body2" color="white" opacity={0.8} mb={2}>
            {description}
          </StyledTypography>
        )}
        {action.type === 'event' && (
          <StyledButton color="white" size="medium" sx={{ mt: 4 }} onClick={onActionClick}>
            {action.label}
          </StyledButton>
        )}
        {action.type === 'internal' && (
          <StyledButton
            component={Link}
            to={action.route}
            color="white"
            size="medium"
            sx={{ mt: 4 }}
          >
            {action.label}
          </StyledButton>
        )}
        {action.type === 'external' && (
          <StyledButton
            component="a"
            href={action.route}
            target="_blank"
            rel="noreferrer"
            color="white"
            size="medium"
            sx={{ mt: 4 }}
          >
            {action.label}
          </StyledButton>
        )}
      </StyledBox>
    </Card>
  );
}

// Setting default values for the props of ActionBackgroundCard
ActionBackgroundCard.defaultProps = {
  label: '',
};

// Typechecking props for the ActionBackgroundCard
ActionBackgroundCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.node,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['event', 'external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onActionClick: PropTypes.func,
};

export default ActionBackgroundCard;
