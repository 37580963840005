import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { Layout } from '../components/layout/PageLanding';
import Information from '../custom/segments/Information';
import Newsletter from '../custom/segments/NewsletterSimple';
import * as config from '../../config/site';

export const query = graphql`
  query {
    page: datoCmsHome {
      title
      teaser
      content
      footer
      primaryActive
      primaryLabel
      primaryLink
      secondaryActive
      secondaryLabel
      secondaryLink
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    siteSetting: datoCmsSiteSetting {
      catalog {
        id
        title
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function LandingPage({ data }) {
  const { page, siteSetting, site } = data;

  const catalogType = config.catalog.catalog.primary;
  const hasCatalog = siteSetting && siteSetting.catalog && siteSetting.catalog.id;
  const catalog = hasCatalog ? siteSetting.catalog.id : '';

  const showNewsletter = config.segments.newsletter.enabled;

  const pageParams = {
    title: page.title,
    teaser: page.teaser,
    content: page.content,
    footer: page.footer,
    buttonOne: {
      show: page.primaryActive,
      title: page.primaryLabel,
      link: page.primaryLink,
    },
    buttonTwo: {
      show: page.secondaryActive,
      title: page.secondaryLabel,
      link: page.secondaryLink,
    },
    cover: { ...page.coverImage },
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={page.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: 4,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        {showNewsletter && <Newsletter />}
      </Card>
    </Layout>
  );
}
