import React from 'react';
import Grid from '@mui/material/Grid';

// appsolut base components
import NewsletterForm from '../elements/NewsletterFormSimple';
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';

function NewsletterSimple() {
  // parameter management
  const defaultImage =
    'https://res.cloudinary.com/appsolut365/image/upload/v1658691512/appsolut365/webportal/default/newsletter_ofyosg.jpg';

  // rendering
  return (
    <StyledBox component="section" pt={{ xs: 3, lg: 8, xl: 16 }} pb={{ xs: 3, lg: 6, xl: 10 }}>
      <StyledBox bgColor="grey-100" pt={12} pb={6} px={{ xs: 0, lg: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6} ml="auto">
            <StyledTypography variant="h4" mb={1}>
              Bleiben Sie informiert!
            </StyledTypography>
            <StyledTypography variant="body2" color="text" mb={3}>
              Melden Sie sich an für unseren Newsletter und erfahren Sie als Erste*r von den
              aktuellen Angeboten.
            </StyledTypography>
            <NewsletterForm />
          </Grid>
          <Grid item xs={12} xl={4} position="relative">
            <StyledBox
              component="img"
              src={defaultImage}
              alt="image"
              maxWidth="18.75rem"
              width="100%"
              borderRadius="lg"
              shadow="xl"
              mt={-18}
              display={{ xs: 'none', xl: 'block' }}
            />
          </Grid>
        </Grid>
      </StyledBox>
    </StyledBox>
  );
}

export default NewsletterSimple;
