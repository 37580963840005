import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import * as config from '../../../config/site';

// appsolut base components
import ApiDataService from '../../api/NewsletterDataService';
import StyledButton from '../../components/controls/StyledButton';
import FormInput from '../../components/controls/FormInput';
import FormMessage from './FormMessage';
import ToastMessage from './ToastMessage';
//import { DisplayFormikState } from '../../utils/formik';

function NewsletterFormSimple() {
  // parameter management
  const initialFormMessage = 'Anfrage fehlgeschlagen. Bitte prüfen Sie Ihre Eingabe.';
  const initialSuccessMessage = 'Ihre Anmeldung wurde erfolgreich durchgeführt';
  const initialErrorMessage = 'Ein Fehler ist aufgetreten, please retry.';
  // state
  const [showFormMessage, setShowFormMessage] = useState(false);
  const [formMessage] = useState(initialFormMessage);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage] = useState(initialSuccessMessage);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const [loading, setLoading] = useState(false);

  // event handler
  const toggleSuccess = () => setShowSuccess(!showSuccess);
  const toggleError = () => setShowError(!showError);
  const sendFormDataToServer = async (payload) => {
    setLoading(true);
    try {
      const flowResult = await ApiDataService.sendNewsletterRequest(payload);
      setLoading(false);
      //setShowSuccess(true);
      setShowFormMessage(false);

      // navigate to caller page
      const navlink = config.pages.thankyou.newsletter;
      navigate(navlink);
    } catch (error) {
      setLoading(false);
      setShowFormMessage(true);
      setShowSuccess(false);
    }
  };

  // data management
  const formInitialValues = {
    email: '',
  };
  const formValidation = Yup.object({
    email: Yup.string()
      .email('Geben Sie bitte eine gültige E-Mail Addresse ein')
      .required('Pflichtfeld'),
  });

  // rendering
  return (
    <Fragment>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidation}
        onSubmit={(values, actions) => {
          try {
            const payload = { ...values };
            sendFormDataToServer(payload);
            actions.resetForm();
          } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
            setShowError(true);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInput name="email" label="Ihre Email Adresse" type="email" fullWidth />
                <FormMessage message={formMessage} open={showFormMessage} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledButton
                  type="submit"
                  disabled={formik.isSubmitting || loading}
                  variant="gradient"
                  color="warning"
                  sx={{ height: '100%' }}
                >
                  Newsletter Anmelden
                </StyledButton>
              </Grid>
            </Grid>
            {/* <DisplayFormikState {...formik} /> */}
          </form>
        )}
      </Formik>
      <ToastMessage
        message={errorMessage}
        open={showError}
        severity={'error'}
        onClose={toggleError}
      />
      <ToastMessage message={successMessage} open={showSuccess} onClose={toggleSuccess} />
    </Fragment>
  );
}

export default NewsletterFormSimple;
