import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import slugify from 'slugify';
import Rellax from 'rellax';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledButton from '../../components/controls/StyledButton';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledHtmlViewer from '../../components/controls/StyledHtmlViewer';
import Navbar from '../../components/shapes/Navbars/PortalNavbar';
import CatalogSwitch from '../../custom/elements/CatalogSwitch';
import PromotionPopup from '../../custom/elements/PromotionPopup';

// custom components
import routes from '../../../config/routes';
import * as pathUtils from '../../utils/path';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1654545382/appsolut365/webportal/default/cover_landing_pon1nr.jpg';

function HeaderPageLanding({ catalogType, catalogId, pageParams, showSearch }) {
  const headerRef = useRef(null);

  // static query
  const data = useStaticQuery(graphql`
    query {
      assortments: allDatoCmsAssortment(sort: { fields: position, order: ASC }) {
        edges {
          node {
            id
            title
            originalId
            noCatalog
            catalogs {
              id
              title
              originalId
            }
          }
        }
      }
      catalogs: allDatoCmsCatalog(sort: { fields: position, order: ASC }) {
        edges {
          node {
            id
            title
            originalId
          }
        }
      }
      siteSetting: datoCmsSiteSetting {
        title
        company
        coverImage {
          gatsbyImageData(width: 1600)
          alt
        }
        proTitle
        proActive
        proTeaser
        proContent
        proFooter
        proButton
        proPrimaryActive
        proPrimaryLabel
        proPrimaryLink
        proSecondaryActive
        proSecondaryLabel
        proSecondaryLink
        proImage {
          gatsbyImageData(width: 450)
          alt
          basename
        }
        proCoverImage {
          gatsbyImageData(width: 800)
          alt
          basename
        }
        catalog {
          id
          title
          originalId
          image {
            gatsbyImageData(width: 450)
            alt
            basename
          }
        }
        catalogAlt {
          id
          title
          originalId
          image {
            gatsbyImageData(width: 450)
            alt
            basename
          }
        }
        logo {
          gatsbyImageData(width: 120)
          alt
        }
      }
    }
  `);
  const { assortments, catalogs, siteSetting } = data;
  const allAssortments = assortments.edges;
  const allCatalogs = catalogs.edges;
  const catalogSettings = nodesUtils.getSiteCatalogs(siteSetting, allCatalogs);
  const settingTitel = siteSetting ? siteSetting.title : '';
  const settingLogo = siteSetting && siteSetting.logo ? siteSetting.logo : {};
  const settingCoverImage = siteSetting && siteSetting.coverImage ? siteSetting.coverImage : {};

  // default cover image
  const hasSettingCoverImage = !_.isEmpty(settingCoverImage);
  const defaultCoverImageSrc = hasSettingCoverImage
    ? getSrc(settingCoverImage.gatsbyImageData)
    : emptyImage;

  const promoSettings = {
    active: siteSetting.proActive,
    title: siteSetting.proTitle,
    imageBase: siteSetting.proImage,
    imageCover: siteSetting.proCoverImage,
    teaser: siteSetting.proTeaser,
    content: siteSetting.proContent,
    footer: siteSetting.proFooter,
    button: siteSetting.proButton,
    buttonMain: {
      active: siteSetting.proPrimaryActive,
      label: siteSetting.proPrimaryLabel,
      link: siteSetting.proPrimaryLink,
    },
    buttonSub: {
      active: siteSetting.proSecondaryActive,
      label: siteSetting.proSecondaryLabel,
      link: siteSetting.proSecondaryLink,
    },
  };

  // NAVIGATION
  // navigation items (assortments)
  const assortBasePath = config.catalog.assortment.slug;
  let navItems = [];
  if (allAssortments && allAssortments.length > 0) {
    allAssortments.forEach(({ node }) => {
      const catalogs = node && node.catalogs ? node.catalogs : [];
      const pageKey = node ? node.id : '';
      const pageTitle = node ? node.title : '';

      const searchIndex = catalogs.findIndex((catalog) => catalog.id == catalogId);
      if (searchIndex >= 0) {
        const pagePath = pathUtils.createPagePathOfMultiCatalog(
          catalogType,
          assortBasePath,
          pageTitle,
          catalogSettings
        );
        const navItem = {
          key: pageKey,
          name: pageTitle,
          route: pagePath,
        };
        navItems.push(navItem);
      }
    });
  }
  const navMerged = _.merge(navItems, routes);

  // search parameter
  const catQueryKey = config.catalog.catalog.querykey;
  const catId = nodesUtils.getOriginalIdFromCatalog(catalogType, catalogSettings);
  const searchBasePath = config.catalog.search.slug;
  const searchRoot = pathUtils.createPagePathOfMultiCatalog(
    catalogType,
    searchBasePath,
    '',
    catalogSettings
  );
  const searchPath = `${searchRoot}?${catQueryKey}=${slugify(catId)}`;

  // logo
  const logoLocal = '../../images/logo.png';
  const hasSettingLogo = !_.isEmpty(settingLogo);
  const logoSrc = hasSettingLogo ? getSrc(settingLogo.gatsbyImageData) : logoLocal;
  const logoAlt = settingLogo && settingLogo.alt ? settingLogo.alt : `Logo ${settingTitel}`;

  // action button
  const bookingPath = config.pages.booking.path;
  const actionButton = {
    label: 'Anfrage',
    type: 'internal',
    color: 'info',
    route: bookingPath,
  };

  // setting collection
  const navSettings = {
    showLogo: true,
    showBrand: false,
    link: '/',
    logo: { src: logoSrc, alt: logoAlt },
    search: { show: showSearch, path: searchPath },
    action: { ...actionButton },
  };

  // HEADER
  // page parameters
  const pageImage = pageParams && pageParams.cover ? pageParams.cover : {};
  const pageTitle = pageParams ? pageParams.title : '';
  const pageTeaser = pageParams ? pageParams.teaser : '';
  const pageContent = pageParams ? pageParams.content : '';
  const pageFooter = pageParams ? pageParams.footer : '';
  const pageButtonOne = pageParams && pageParams.buttonOne ? pageParams.buttonOne : {};
  const hasPageButtonOne = !_.isEmpty(pageButtonOne) && pageButtonOne.show;
  const pageButtonTwo = pageParams && pageParams.buttonTwo ? pageParams.buttonTwo : {};
  const hasPageButtonTwo = !_.isEmpty(pageButtonTwo) && pageButtonTwo.show;

  // cover image
  const hasPageImage = !_.isEmpty(pageImage);
  const pageImageSrc = hasPageImage ? getSrc(pageImage.gatsbyImageData) : defaultCoverImageSrc;

  // setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -10,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <Fragment>
      <Navbar brand={settingTitel} settings={navSettings} routes={navMerged} sticky center />
      <StyledBox
        ref={headerRef}
        height="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${pageImageSrc})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid container item xs={12} mx="auto">
            <Grid
              item
              xs={12}
              md={8}
              justifyContent={{ xs: 'center', md: 'start' }}
              sx={{ textAlign: { xs: 'center', md: 'left' } }}
            >
              <StyledTypography
                variant="h1"
                color="white"
                mt={{ xs: 9, md: 6, lg: 1 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                })}
              >
                {pageTitle}
              </StyledTypography>
              <Stack>
                {pageTeaser && (
                  <StyledTypography
                    variant="body1"
                    color="white"
                    mt={1}
                    pr={{ md: 6, lg: 12, xl: 16 }}
                    opacity={0.8}
                  >
                    {pageTeaser}
                  </StyledTypography>
                )}
                {pageContent && (
                  <StyledHtmlViewer>
                    <StyledTypography
                      component="div"
                      variant="body1"
                      color="white"
                      mt={1}
                      pr={{ md: 6, lg: 12, xl: 16 }}
                      opacity={0.9}
                      dangerouslySetInnerHTML={{ __html: pageContent }}
                    />
                  </StyledHtmlViewer>
                )}
              </Stack>
              <Stack direction="row" spacing={1} mt={6} mb={3}>
                {hasPageButtonOne && (
                  <StyledButton variant="gradient" to={pageButtonOne.link} color="info">
                    {pageButtonOne.title}
                  </StyledButton>
                )}
                {hasPageButtonTwo && (
                  <StyledButton variant="gradient" to={pageButtonTwo.link} color="white">
                    {pageButtonTwo.title}
                  </StyledButton>
                )}
              </Stack>
              <Stack>
                {pageFooter && (
                  <StyledHtmlViewer>
                    <StyledTypography
                      component="div"
                      variant="button"
                      color="white"
                      fontWeight="regular"
                      mt={5}
                      pr={{ md: 6, lg: 12, xl: 16 }}
                      opacity={0.9}
                      dangerouslySetInnerHTML={{ __html: pageFooter }}
                    />
                  </StyledHtmlViewer>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={{ xs: 'center', md: 'end' }}
              sx={{ textAlign: { xs: 'center', md: 'right' } }}
            >
              <CatalogSwitch catalogType={catalogType} catalogSettings={catalogSettings} />
              <PromotionPopup promoSettings={promoSettings} />
            </Grid>
          </Grid>
        </Container>
      </StyledBox>
    </Fragment>
  );
}

HeaderPageLanding.defaultProps = {
  showSearch: true,
};

HeaderPageLanding.propTypes = {
  catalogType: PropTypes.string,
  catalogId: PropTypes.string,
  pageParams: PropTypes.instanceOf(Object),
  showSearch: PropTypes.bool,
};

export default HeaderPageLanding;
